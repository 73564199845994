import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import Container from "@components/container"
import PrimaryCta from "@components/PrimaryCta"
import { Heading2 } from "@styles/ui"

const TwoColumns = ({
  children,
  heading,
  imageData,
  altText,
  imgFirst,
  mobileInverse,
  cta,
}) => {
  return (
    <Container grid>
      <div
        css={[
          (imgFirst || mobileInverse) && tw`order-2`,
          tw`md:(col-span-6)`,
          mobileInverse && tw`md:order-[unset]`,
        ]}
      >
        <h2 css={[Heading2, tw`mt-[15px] xl:mt-0`]}>{heading}</h2>
        {children}
        {cta && (
          <div tw="text-center mt-[15px] md:(mt-[25px] text-left)">
            <PrimaryCta
              isExternal={cta.isExternal}
              url={cta.url}
              mdSmall={cta.mdSmall}
            >
              {cta.text}
            </PrimaryCta>
          </div>
        )}
      </div>
      <div
        css={[
          tw`mt-8 md:(col-span-6 mt-0)`,
          (imgFirst || mobileInverse) && tw`order-1 mt-0`,
          mobileInverse && tw`md:order-[unset]`,
        ]}
      >
        <div
          css={[
            tw`col-span-6 text-center max-w-[90%] mx-auto md:(text-right mb-0  mx-0 flex h-full items-center)`,
            imgFirst && tw`md:text-left`,
          ]}
        >
          <GatsbyImage image={getImage(imageData)} alt={altText} />
        </div>
      </div>
    </Container>
  )
}

export default TwoColumns

TwoColumns.prototypes = {
  imageData: PropTypes.object.isRequired,
  altText: PropTypes.string,
  imgFirst: PropTypes.bool,
  mobileInverse: PropTypes.bool,
  cta: PropTypes.object,
  maxContentWidth: PropTypes.string,
}

TwoColumns.defaultProps = {
  altText: "",
  imgFirst: false,
  mobileInverse: false,
  cta: null,
  maxContentWidth: "",
}
