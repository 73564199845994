import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Container from "@components/container"
import StaticHero from "@components/staticHero"
import DownloadItem from "@components/DownloadItem"
import TwoColumns from "@components/twoColumnsTextCta"
import Callout from "@components/narcolepsy/CallOut"
import PrimaryCta from "@components/PrimaryCta"
import { Heading2, sectionMd } from "@styles/ui"

// Assets
import xywavColourfulLogo from "@images/logos/xywav-logo-large-callout.svg"

const downloadData = [
  {
    title: "Doctor Discussion Guide",
    description:
      "To help prepare yourself to talk to your doctor about XYWAV, download and read our Doctor Discussion Guide.",
    pdf: "/pdf/doctor-discussion-guide-narcolepsy.pdf",
    size: "127 KB",
  },
  {
    title: "XYWAV Patient Brochure",
    description:
      "Learn important information about XYWAV, including what it is, things to know about your treatment experience, and much more.",
    pdf: "/pdf/patient-brochure-narcolepsy.pdf",
    size: "2.78 MB",
  },
]

const PatientResourcesPage = ({ location, data }) => {
  return (
    <Layout location={location} exitModalColour="red">
      <Seo
        title="Patient Resources | XYWAV®"
        description="Explore educational resources and support for your XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution treatment journey. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <StaticHero eyebrow="Additional patient resources" gradient="red">
        Looking for more XYWAV treatment information?
      </StaticHero>

      {/* Educational webinars */}
      <section css={sectionMd}>
        <TwoColumns
          heading={
            <>
              Watch a XYWAV <br tw="md:hidden" />
              Educational Webinar
            </>
          }
          imgFirst
          imageData={data.colImage}
          cta={{
            url: "/narcolepsy/educational-webinars/",
            text: "View webinars",
            isExternal: false,
            mdSmall: true,
          }}
        >
          <p tw="mt-5 lg:(mt-7)">
            Learn about XYWAV from experts in narcolepsy through webinars on
            demand. These sessions led by healthcare professionals will help you
            gain a deeper understanding of your condition as well as how XYWAV
            may help treat your cataplexy and/or excessive daytime sleepiness
            (EDS).
          </p>
        </TwoColumns>
      </section>

      <section
        css={[sectionMd, tw`bg-seashell overflow-hidden`]}
        id="patient-brochure"
      >
        <Container>
          <img
            src={xywavColourfulLogo}
            alt=""
            tw="hidden lg:(block absolute -left-[230px] -bottom-[60px] w-[450px])"
          />
          <div>
            <h2 css={[Heading2, tw`mb-4 text-center lg:mb-10`]}>
              These useful resources can&nbsp;help
            </h2>
            <DownloadItem downloads={downloadData} theme="narcolepsy" />
          </div>
        </Container>
      </section>

      {/* Mentor program */}
      <Callout
        theme="red"
        heading="Support from someone like you: the XYWAV Mentor Program"
        id="mentor-program-patient-resources"
      >
        <p tw="mb-6 font-extrabold">
          The XYWAV Mentor Program connects you <nobr>1-on-1</nobr> with someone
          who has firsthand experience taking XYWAV. The XYWAV Patient Mentors
          understand what you are going through. They listen to you and support
          you, providing practical tips and meaningful insight by sharing their
          experiences. Get updates about the XYWAV Mentor Program, and more.
        </p>
        <PrimaryCta url="/narcolepsy/stay-connected/" isWhite>
          I&apos;d like a mentor
        </PrimaryCta>
      </Callout>

      {/* Jazz webinars */}
      <section css={sectionMd}>
        <TwoColumns
          heading="Hear from the experts how XYWAV can support you through your narcolepsy treatment journey"
          imageData={data.colImageTwo}
          imgFirst
          cta={{
            url: "https://www.jazzwebinars.com/xywav-narcolepsy/",
            text: "Visit Jazzwebinars.com",
            isExternal: true,
          }}
        >
          <p tw="text-sm mt-4 xl:(text-xl mt-6)">
            Get important, up-to-date information on XYWAV delivered to you by
            healthcare professionals through interactive live webinars.
          </p>
        </TwoColumns>
      </section>
    </Layout>
  )
}

export default PatientResourcesPage

export const query = graphql`
  query {
    colImage: file(
      relativePath: { eq: "patient-resources/educational-webinars.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 470, placeholder: NONE)
      }
    }
    colImageTwo: file(
      relativePath: { eq: "patient-resources/educational-webinars2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 470, placeholder: NONE)
      }
    }
  }
`
